import request from '@/utils/request'

// 查询所有平台角色
export function findSystemRoles(params) {
  return request({
    url: `/system/roles`,
    method: 'get',
    params
  })
}

// 查询租户角色
export function findTenantRoles(params) {
  return request({
    url: `/tenant/roles`,
    method: 'get',
    params
  })
}

export function findTenantRoleForm(id) {
  return request({
    url: `/tenant/roles/${id}/form`,
    method: 'get'
  })
}

// 更新角色有效性
export function updateRoleEffective(id, data) {
  return request({
    url: `/roles/${id}/effective`,
    method: 'patch',
    data
  })
}

// 获取角色下来选项
export function findRoleOptions(params) {
  return request({
    url: `/roles/options`,
    method: 'get',
    params
  })
}

// 查询名下租户角色
export function findTenantRolesOptions(params) {
  return request({
    url: `/tenant/roles/options`,
    method: 'get',
    params: params
  })
}

// 租户上级角色选项
export function findTenantParentRoleOptions(params) {
  return request({
    url: `/tenant/roles/parent/options`,
    method: 'get',
    params: params
  })
}

// 创建角色
export function createRole(data) {
  return request({
    url: `/roles`,
    method: 'post',
    data
  })
}

// 更新角色
export function updateRole(roleId, data) {
  return request({
    url: `/roles/${roleId}`,
    method: 'put',
    data
  })
}

// 获取任务权限
export function findRoleTaskAuthorityOptions() {
  return request({
    url: `/roles/task_authority/options`,
    method: 'get'
  })
}

