<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑账号"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="姓名">
          <a-input
            v-decorator="['emp_name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入姓名' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入手机号' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="登录工号">
          <a-input
            v-decorator="['emp_no', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入登录工号' },
                { pattern: /^[a-zA-Z0-9_]{4,100}$/, message: '只能由4-100位 字母、数字 或 下划线 组成' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="登录密码">
          <a-input-password
            autocomplete="new-password"
            v-decorator="['password', {
              rules: [
                { message: '请输入登录密码' },
                { min: 6, message: '最少6个字符' },
                { max: 100, message: '最多100个字符' },
                { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="角色">
          <a-select
            v-decorator="['role_id', {
              rules: [{ required: true, message: '请选择角色' }]
            }]"
            :loading="roleOptions.length === 0"
          >
            <a-select-option
              v-for="role in roleOptions"
              :key="role.id"
              :value="role.id"
              :disabled="!role.effective"
            >
              {{ role.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="工作地点">
          <a-select
            :loading="loadingFuneralHomeOptions"
            mode="multiple"
            v-decorator="['funeral_home_ids', {
              rules: [{ required: true, message: '请选择工作地点' }]
            }]"
          >
            <a-select-option
              v-for="home in funeralHomeOptions"
              :key="home.id"
              :value="home.id"
            >
              {{ home.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="照片">
          <span
            class="tip-text"
          >支持png/jpg格式照片，10M以内, 建议尺寸宽高比180:180px，最多1张
          </span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="1"
            :default-file-list="attachments"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateUser, verificationPhoneNumber } from '@/api/user'
import { findTenantRolesOptions } from '@/api/role'
import { findFuneralHomeOptions } from '@/api/funeral_home'
import UploadImage from '@/components/Upload/Image'

export default {
  name: 'EditUser',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'user' }),
      roleOptions: [],
      funeralHomeOptions: [],
      loadingFuneralHomeOptions: false,
      submitting: false,
      isAddWatermark: false,
      attachments: [], // 图片
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchRoleOptions()
    this.fetchFuneralHomeOptions()
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.defaultFileList = []
      this.data = Object.assign({}, {
        emp_no: this.record.emp_no,
        emp_name: this.record.emp_name,
        phone_number: this.record.phone_number,
        role_id: this.record.role_id,
        password: ''
      })
      if (this.record.funeral_home_ids && this.record.funeral_home_ids.length > 0) {
        this.data = Object.assign({}, this.data, { funeral_home_ids: this.record.funeral_home_ids })
      }
      if (this.record.images != null && this.record.images.length > 0) {
        this.record.images.forEach((item, index) => {
          this.defaultFileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
        this.attachments = this.defaultFileList
      })
    },
    // 加载角色选项
    fetchRoleOptions() {
      findTenantRolesOptions(Object.assign({}, {})).then((res) => {
        this.roleOptions = res.data
      })
    },

    fetchFuneralHomeOptions() {
      this.loadingFuneralHomeOptions = true
      findFuneralHomeOptions({ user_id: this.record.id }).then((res) => {
        if (res.data.default_options && res.data.default_options.length > 0) {
          this.funeralHomeOptions = this.$lodash.concat(res.data.default_options, res.data.options)
          this.funeralHomeOptions = this.$lodash.uniqWith(this.funeralHomeOptions, this.$lodash.isEqual)
        }
      })
      this.loadingFuneralHomeOptions = false
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },
    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      const vm = this
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          verificationPhoneNumber({ phone_number: values.phone_number, id: this.record.id }).then(res => {
            if (res.code === 30003) {
              this.submitting = false
              this.$confirm({
                title: '手机号已存在',
                content: '是否继续创建',
                okText: '继续',
                cancelText: '取消',
                onOk() {
                  const editData = {
                    ...values,
                    attachments: vm.$lodash.map(vm.attachments, 'response'),
                    funeral_home_ids: []
                  }
                  updateUser(vm.record.id, editData).then((res) => {
                    if (res.code === 0) {
                      // 关闭模态框
                      vm.isShow = false
                      // 告知父组件已完成
                      vm.$emit('completed')
                    }
                    vm.submitting = false
                  })
                },
                onCancel() {
                  vm.submitting = true
                }
              })
            }
            if (res.code === 0) {
              const editData = {
                ...values,
                attachments: this.$lodash.map(this.attachments, 'response')
              }
              updateUser(this.record.id, editData).then((res) => {
                if (res.code === 0) {
                  // 关闭模态框
                  vm.isShow = false
                  // 告知父组件已完成
                  vm.$emit('completed')
                }
                vm.submitting = false
              })
            }
          })
        }
      })
    }
  }
}
</script>
